.image-modal {
    display: flex;
    flex-direction: column;
} 

.image-modal-overlay {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-modal img {
    display: block;
    width: 100%;
    margin: 20px;
    box-sizing: border-box;
    max-width: 800px;
    max-height: 93vh;
    margin: 0 auto;
}

.image-modal__close-button {
    background-color: transparent;
    border: 0;
    padding: 20px;
    color: #fff;
    font-size: var(--font-size-large);
    cursor: pointer;
}

.image-modal__close-button:hover {
    text-decoration: underline;
}
  
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
  