footer {
    position: relative;
    z-index: 0;
    margin-top: -100px;
    padding-top: 50px;
    background: #F5F5F5;
}

footer a,
footer a:link {
    color: var(--color-text);
}

footer a:hover {
    color: var(--color-primary);
}