header {
    height: var(--site-bg-height);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
}

.link,
a.link,
a.link:link {
    font-size: var(--font-size-base);
    font-family: var(--font-family-montserrat);
    color: #fff;
    text-decoration: none;
}

.link:hover,
a.link:hover {
    text-decoration: underline;
    color: #fff;
}

.siteName {
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-large);
}

.header--no-hero {
    height: auto;
}

.header__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: calc(var(--gutter) / 2);
}

.header__nav--right {
    margin-left: auto;
}

.hero-content {
    max-width: var(--content-max-width);
    padding: var(--gutter); 
    margin: 0 auto;
    color: #fff;
}

@media only screen and (min-width: 576px) {
    .link,
    a.link,
    a.link:link {
        font-size: var(--font-size-large);
    }
    .siteName,
    a.siteName,
    a.siteName:link {
        font-size: var(--font-size-larger);
    }

    .header__nav {
        padding: var(--gutter);
    }
}