.button,
a.button,
a.button:link {
  padding: 8px 30px;
  display: inline-block;
  background: #fff;
  border: 1px solid var(--color-text-lighter);
  border-radius: 25px;
  font-size: var(--font-size-large);
  font-family: var(--font-family-bold);
  font-weight: 700;
  padding: 10px 30px;
  color: var(--color-text-button);
  cursor: pointer;
  text-decoration: none;
}

.button--selected,
.button--primary,
a.button--primary,
a.button--primary:link,
a.button--selected,
a.button--selected:link {
  background: var(--color-primary);
  color: #fff;
  border-color: var(--color-primary);
}

.button:hover {
  opacity: 0.8;
  text-decoration: none;
}

.button--primary:hover {
  color: #fff;
}

.button:active {
  border-color: #000;  
}

.button:disabled,
.button[disabled] {
  border-color: #ccc;
  background-color: #ccc;
  color: var(--color-text-lighter);
  cursor: not-allowed;
}

.button--arrow-right {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button--arrow-back {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0;
}

.button--arrow-right:after {
  content:"";
  display: inline-block;  
  background-image: url('../images/arrow-right-white.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 24px;
  max-width: 50px;
  margin-left: 10px;
}

.button[disabled].button--arrow-right:after {
  background-image: url('../images/arrow-right-disabled.svg');  
}

.button--arrow-back:before {
  content:"";
  display: inline-block;  
  background-image: url('../images/arrow-back-gray.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 24px;
  max-width: 50px;
}

.button--arrow-back span {
  display: none;
}

@media only screen and (min-width: 576px) {
  .button,
  a.button,
  a.button:link {
    font-size: var(--font-size-larger);
    padding: 10px 40px;
  }

  .button--arrow-back {
    padding: 8px 30px;
    width: auto;
    height: auto;
    border-radius: 25px;
  }

  .button--arrow-back span {
    display: inline-block;
    padding-left: 10px;
  }
}
  