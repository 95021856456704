/* .wizard {
    padding-bottom: 60px;
} */

.step-actions {
    /* position: fixed; */
    position: sticky;
    box-sizing: border-box;
    background-color: #fff;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(var(--gutter) / 4) calc(var(--gutter) / 2);
    box-shadow: 0 6px 24px 0 rgba(0,0,0,0.25);
}

.step-actions__button--prev {
    font-size: 0;
}

@media only screen and (min-width: 576px) {
    .wizard {
        padding-bottom: 0;
    }

    .step-actions {
        /* position: static; */
        position: -webkit-sticky;
        position: sticky;
        width: 100%;
        /* box-shadow: none; */
        border-top: 1px solid var(--color-gray-light);
        padding-top: calc(var(--gutter) / 2);
        padding-bottom: calc(var(--gutter) / 2);
        margin-top: var(--gutter);
    }

    .step-actions__button {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .step-actions__button--prev {
        font-size: var(--font-size-larger);
    }
}