.action-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.action-list__title {
    margin-bottom: 30px;
}

.action-list__item {
    position: relative;
    margin: 0 0 calc(var(--gutter) / 2);;
    padding: 0 0 calc(var(--gutter) / 2);    
    border-bottom: 1px solid var(--color-gray-light);
}

.action {
    height: 100%;
    display: flex;
    flex-direction: row;
}

.action .action__column--figure {
    margin: 0;
    float: none;
}

.action__column--content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: calc(var(--gutter) / 2);
    margin-right: calc(var(--gutter) / 2);
    margin-top: 5px;
}

.action__content {
    flex-grow: 1;
    padding-bottom: 15px;
}

.action__content h2:first-of-type {
    margin-top: 0;
}

.action__content p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
}

.action .action__image {
    box-sizing: border-box;
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.action .action__image--clickable {
    cursor: pointer;
}

.action__content .action__title {
    font-size: var(--font-size-larger);
    margin-bottom: 10px;
}

.action-list__group__title {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.action-list__group__title span {
    position: relative;
    display: inline-block;    
    font-size: var(--font-size-large);
    padding: 10px 5%;
    background-color: #fff;
}

.action-list__group__title:before {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    content: "";
    margin-top: -3px;
    border-bottom: 6px solid var(--color-primary);
    opacity: 0.25;
}

.action-list--row:first-of-type {
    padding-top: calc(var(--gutter) / 2);    
    border-top: 1px solid var(--color-gray-light);
}

.action-list--row .action__image {
    width: 110px;
    height: 110px;
}

@media only screen and (min-width: 576px) {
    .action-list--row:first-of-type {
        padding-top: var(--gutter);
    }

    .action-list--grid .action {
        flex-direction: column;
    }

    .action-list--grid .action__column {
        margin-right: 0;
    }

    .action-list--grid .action__column {
        margin-left: 0;
    }

    .action-list--grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--gutter);
        row-gap: 0;
    }

    .action-list--grid .action-list__item {
        padding-right: 0;
        margin-bottom: var(--gutter);
    }

    .action-list__group__title {
        margin-bottom: 30px;
    }

    .action-list__group__title span {
        font-size: var(--font-size-largest);
    }

    .action-list__item {
        margin: 0 0 var(--gutter);
        padding: 0 0 var(--gutter);
    }

    .action-list--grid .action-list__item {
        margin: 0 0 30px;
        padding: 0;
        border-bottom: 0;
    }

    .action__content .action__title {
        font-size: 30px;
        margin-bottom: 15px;
    }

    .action-list--grid  .action__column--content {
        margin-top: 10px;
    }

    .action  .action__image {
        height: 175px;
        width: 100%;
    }
    
    .action-list--row .action__image {
        width: 200px;
        height: 200px;
    }

    .action-list--row .action__column--content {
        margin-right: var(--gutter);
    }

    .action-list--row .action {
        flex-direction: row;
    }
}

@media only screen and (min-width: 768px) {
    .action-list {
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .action-list--row .action__image {
        width: 300px;
        height: 290px;
    }
}