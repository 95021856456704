@import-normalize;

@font-face {
  font-family: 'Sentinel-Book';
  src: url('../fonts/Sentinel/Sentinel-Book.otf') format('opentype'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Sentinel-Medium';
  src: url('../fonts/Sentinel/Sentinel-Medium.otf') format('opentype'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Sentinel-Book-Italic';
  src: url('../fonts/Sentinel/Sentinel-BookItalic.otf') format('opentype'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Sentinel-Semibold';
  src: url('../fonts/Sentinel/Sentinel-Semibold.otf') format('opentype'); /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Sentinel-Bold';
  src: url('../fonts/Sentinel/Sentinel-Bold.otf') format('opentype'); /* Legacy iOS */
  font-display: swap;
}

:root {
  --gutter: 40px;
  --content-max-width: 940px;
  --site-bg-height: 1024px;

  --color-primary: #4B810D;
  --color-text: #3A3D42;
  --color-text-lighter: #7F8389;
  --color-text-button: #525252;
  --color-gray-light: #CDCDCD;
  --color-gary-dark: #73747D;

  --font-family-normal: Sentinel-Book, sans-serif;
  --font-family-bold: Sentinel-Bold, sans-serif;
  --font-family-semibold: Sentinel-Semibold, sans-serif;
  --font-family-italic: Sentinel-Book-Italic, sans-serif;

  --font-family-montserrat: 'Montserrat', sans-serif;

  --font-size-small: 14px;
  --font-size-base: 16px;
  --font-size-large: 18px;  
  --font-size-larger: 21px;
  --font-size-largest: 24px;
}

body, html {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--font-family-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  font-size: var(--font-size-base);
  line-height: 1.32;
}

@media only screen and (min-width: 576px) { 
  body {
    font-size: var(--font-size-large);
    line-height: 1.3;
  }
}

.center {
  text-align: center;
}

.font-size--large {
  font-size: var(--font-size-large);
}

.font-size--small {
  font-size: var(--font-size-small);
}

h1, h2, h3, h4, h5 {
  font-family: var(--font-family-semibold);
  line-height: 1.1;
}

h5, .h5 {
  font-size: 0.83em;
}

h2, .h2 {
  font-size: 30px;
}

p {
  margin-bottom: 1.75rem;
  margin-top: 1.2rem;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: 1px solid var(--color-gray-light);
}

a, a:link {
  color: var(--color-primary);
  text-decoration: underline;
}

a:hover {
  color: #000;
  text-decoration: underline;
}

.page-title {
  font-size: 32px;
  font-family: var(--font-family-bold);
  color: var(--color-primary);
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.page-title__sub-title {
  display: block;
  color: #444444;
  font-family: var(--font-family-italic);
  font-weight: 400;
  font-size: 20px;
  line-height: 1.1;
  padding-top: 5px;
}

.chart-title {
  font-size: var(--font-size-large);
  text-align: center;
}

.chart-title--stroke {
  position: relative;
  width: 100%;
  margin-bottom: 0;
}

.chart-title--stroke span {
  position: relative;
  display: inline-block;    
  padding: 10px 5%;
  background-color: #fff;
}

.chart-title--stroke:before {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  content: "";
  margin-top: 0;
  border-bottom: 2px solid var(--color-primary);
  opacity: 0.25;
}

.figure img {
  display: block;
  width: 100%;
}

.figure--align-left {
  margin: 0 2.5rem 1.25rem 0;
  float: left;
  display: block;
}

@media only screen and (min-width: 576px) {
  .page-title {
    font-size: 48px;
  }

  .page-title__sub-title {
    font-size: 30px;
    padding-top: 0;
  }
}

a.skip-to-content-link {
  display: block;
  box-sizing: border-box;
  background: var(--color-primary);
  color: #fff;
  padding: calc(var(--gutter) / 4);
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  z-index: 2;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}

.root {
  height: 100%;
}

.site {
  position: relative;
  z-index: 1;
  height: 100%;
}

.center {
  text-align: center;
}

.main-content {
  position: relative;
  z-index: 1;
}

.content {
  max-width: var(--content-max-width);
  margin: 0 auto;
}

.content h2:first-child {
  margin-top: 0;
}

.content__wrapper {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  padding-left: calc(var(--gutter) / 2);
  padding-right: calc(var(--gutter) / 2);
}

.content--card {
  background: #fff;
  margin: 0 auto var(--gutter) auto;
  min-height: 440px;
  box-shadow: 0 6px 24px 0 rgba(0,0,0,0.25);
}

.content--card__hero {
  position: relative;
}

.content--card__hero__image {
  display: block;
  width: 100%;
}

.content--card__hero__title {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 36px;
  font-family: var(--font-family-bold);
  line-height: 1;
}

.modal-action-image img {
  width: 100%;
  max-width: 800px;
}
.no-bottom-padding {
  padding-bottom: 0;
}

.no-top-padding {
  padding-top: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.no-top-margin {
  margin-top: 0;
}

@media only screen and (min-width: 576px) { 

  .page-title {
    margin-bottom: 2.5rem;
  }

  hr {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .content--card__hero__title {
    font-size: 50px;
  }

  .content__wrapper {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }

  .content--side-padding {
    padding-left: calc(var(--gutter));
    padding-right: calc(var(--gutter));
  }

  .action-list__container {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }

  .highcharts-tooltip span {
    width: 350px;
    white-space:normal !important;
  }
}

@media only screen and (min-width: 768px) {
  .content--card__hero__title {
    font-size: 60px;
  }
}

@media only screen and (min-width: 940px) {
  .content--card {
    border-radius: 24px;
  }

  .content--card__hero__image {
    border-top-left-radius: 24px;  
    border-top-right-radius: 24px;  
  }

  .content--card__hero__title {
    font-size: 80px;
  }
}

.site-bg {
  position: absolute;
  width: 100%;
  height: var(--site-bg-height);
  z-index: 0;
  background-image: url('../images/nauta-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-color: #333;
}

.text-content--narrow {
  max-width: 740px;
  margin: 0 auto;
}

.home-hero {
  background-image: url(../images/nauta-main.jpg);
  background-repeat: no-repeat;
  background-position: center top;
}