.welfare-gauge-data-label {
    font-family: var(--font-family-montserrat);
    font-weight: 300;
    font-size: 50px;
    position: relative;
    top: 30px;
}

.welfare-gauge-data-label span {
  font-size: 26px;
}