.text {
    margin-bottom: 10px;
}

.text p:first-of-type {
    margin-top: 0;
}

.text_truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.text_truncated p {
    margin-bottom: 0;
}

.text_not_truncated p {
    margin-bottom: 1.5rem;
}

.readMore {
    display: block;
    position: relative;
    width: 100%;
    max-width: 530px;
    border: 0;
    text-align: center;
    margin: 0 auto calc(var(--gutter) / 2);
}

.readMore button {
    padding: 0;
    background: none;
    cursor: pointer;
    border: 1px solid transparent;
    font: var(--font-family-semibold);
    font-size: 14px;
    color: var(--color-gary-dark);
    white-space: nowrap;
}

.readMore:before {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    content: "";
    border-bottom: 1px solid var(--color-text-lighter);
}

.readMore:hover {
    color: var(--color-primary);
}

.readMore:active {
    border-color: #000;
    outline: 1; 
}

.readMore button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    margin-left: 0;
    background-color: #fff;
    text-transform: uppercase;
}

.readMore button:active {
    border: 1px solid #000;
}

.readMore button:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    padding-left: 10px;
    background-image: url('../images/arrow-more.svg');
    background-position: center center;
    background-repeat: no-repeat;
}

.readMorecompact {
    margin-bottom: 0;
}

.readMorecompact button {
    margin-bottom: 0;
    padding: 10px 0;
}

.readMoreLess {
    padding-top: 0; 
}

.readMoreLess button:after {
    background-image: url('../images/arrow-less.svg');
}

@media only screen and (min-width: 480px) {
    .readMorecompact button {  
        padding: 10px 20px;
        margin-left: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .readMorecompact:before {    
        display: none;
    }
}