.welfareContainer {
    max-width: 720px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin: 0 auto var(--gutter);
}

.welfareImageContainer {
    display: none;
}

.welfarepointsResultContainer {
  border: 1px solid #B7B7B7;
  padding: var(--gutter) calc(var(--gutter) / 2);
  max-width: 340px;
  margin: 0 auto var(--gutter);
  margin: 0 auto;
  box-sizing: border-box;
}

.welfarepointsResultTitle {
    margin: 0;
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-large);
    font-weight: 700;
}

@media only screen and (min-width: 840px) {
    .welfareImageContainer {
        display: block;
        margin: 0 var(--gutter) 0 0;
    }
    .welfareImage,
    .welfareImage img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .welfareImage img {
        object-fit: cover;
    }
}